var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "순찰위치", noMarginPadding: true },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.popupParam.disabled
                    ? _c("c-btn", {
                        attrs: { label: "지도 변경", icon: "place" },
                        on: { btnClicked: _vm.selectMap },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.popupParam.disabled && _vm.sopMapId
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addArea },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.popupParam.disabled && _vm.sopMapId
                    ? _c("c-btn", {
                        attrs: { label: "적용", icon: "check" },
                        on: { btnClicked: _vm.applyArea },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "card-detail" },
            [
              _vm.sopMapId
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 container",
                      },
                      [
                        _c(
                          "q-img",
                          {
                            staticStyle: { width: "800px" },
                            attrs: {
                              src: _vm.mapSrc,
                              "spinner-color": "white",
                              contain: true,
                            },
                          },
                          [
                            _vm.popupParam.maps &&
                            _vm.popupParam.maps.length > 0
                              ? _vm._l(
                                  _vm.popupParam.maps,
                                  function (item, idx) {
                                    return _c(
                                      "VueDraggableResizable",
                                      {
                                        key: idx,
                                        ref: "markImage",
                                        refInFor: true,
                                        staticClass: "markImage",
                                        attrs: {
                                          resizable: false,
                                          parent: true,
                                          draggable:
                                            _vm.editable &&
                                            !_vm.popupParam.disabled,
                                          x: item.locationXcoordinate,
                                          y: item.locationYcoordinate,
                                          w: 30,
                                          h: 30,
                                          grid: [30, 30],
                                        },
                                        on: {
                                          dragging: (x, y) =>
                                            _vm.onDrag(x, y, item),
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(idx + 1) + " ")]
                                    )
                                  }
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 container",
                      },
                      [
                        _vm.popupParam.maps && _vm.popupParam.maps.length > 0
                          ? _vm._l(_vm.popupParam.maps, function (item, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-9",
                                      staticStyle: {
                                        position: "relative",
                                        top: "15px",
                                      },
                                    },
                                    [
                                      _c("c-text", {
                                        attrs: {
                                          editable: _vm.editable,
                                          disabled: _vm.popupParam.disabled,
                                          label: idx + 1 + "번 순찰장소",
                                          name: "mapContent",
                                        },
                                        on: {
                                          dataChange: function ($event) {
                                            return _vm.dataChange(item)
                                          },
                                        },
                                        model: {
                                          value: item.mapContent,
                                          callback: function ($$v) {
                                            _vm.$set(item, "mapContent", $$v)
                                          },
                                          expression: "item.mapContent",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-3",
                                      staticStyle: {
                                        position: "relative",
                                        top: "20px",
                                      },
                                    },
                                    [
                                      _vm.editable && !_vm.popupParam.disabled
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "제외",
                                              icon: "remove",
                                            },
                                            on: {
                                              btnClicked: function ($event) {
                                                return _vm.removePoint(idx)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                : [
                    _c(
                      "q-chip",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { square: "", icon: "info" },
                      },
                      [_vm._v(" " + _vm._s(_vm.mapMessage) + " ")]
                    ),
                  ],
            ],
            2
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }